export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

const serverNoDic: Record<number, string> = {
  7: 'https://cotoapli.lacoon.life/',
  8: 'https://svr8.genpaku.biz/',
};
const ServerHelper = {
  getDictionary: (): Record<number, string> => serverNoDic,

  getValue: (key: number): string => serverNoDic[key] || '',
};
export default ServerHelper;
